<template>
  <div>
    <Pane>
      <div class="center" style="flex: 1" slot="extra">
        <a-button
          type="primary"
          shape="round"
          @click="showList = !showList"
        >{{ showList ? "卡片" : "列表" }}</a-button>
      </div>
    </Pane>

    <a-card class="container">
      <div>
        <a-form-model layout="inline" @keyup.enter.native="query">
          <a-form-model-item>
            <a-input v-model="form.name" placeholder="姓名" style="width: 120px" />
          </a-form-model-item>
          <a-form-model-item>
            <a-select placeholder="性别" v-model="form.sex" style="width: 120px">
              <a-select-option
                v-for="item in sex"
                :key="item.value"
                :value="item.value"
              >{{ item.name }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item>
            <a-input-number v-model="form.ageMin" placeholder="最小年龄" style="width: 120px" />
          </a-form-model-item>
          <a-form-model-item>
            <a-input-number v-model="form.ageMax" placeholder="最大年龄" style="width: 120px" />
          </a-form-model-item>

          <a-form-model-item>
            <a-input v-model="form.position" placeholder="职位" style="width: 120px" />
          </a-form-model-item>
          <a-form-model-item>
            <a-input v-model="form.rank" placeholder="职级" style="width: 120px" />
          </a-form-model-item>

          <a-form-model-item>
            <a-select
              placeholder="部门"
              v-model="form.belongDept"
              show-search
              style="width: 120px"
              :filter-option="$selectFilterOption"
            >
              <a-select-option
                v-for="item in organizationList"
                :key="item.id"
                :value="item.id"
              >{{ item.uniqueName }}</a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-model-item>
            <a-select
              placeholder="状态"
              v-model="form.status"
              show-search
              style="width: 120px"
              :filter-option="$selectFilterOption"
            >
              <a-select-option
                v-for="item in employeeStatusList"
                :key="item.value"
                :value="item.value"
              >{{ item.name }}</a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-model-item>
            <a-space>
              <a-button @click="query" type="primary">查询</a-button>
              <a-button @click="reset">重置</a-button>
              <Search @search="search" :searchValues="searchValues" />
            </a-space>
          </a-form-model-item>
        </a-form-model>
        <Padding size="large" />

        <div class="left">
          <a-space>
            <a-button
              v-if="$getPermission($route.path + '/add')"
              type="primary"
              shape="round"
              @click="$router.push($route.path + '/add')"
            >创建</a-button>

            <FileUploader @refresh="getList" v-if="$getPermission($route.path + '/add')" />
            <a-button
              type="primary"
              shape="round"
              style="background: green; border-color: green"
              @click="onDownload"
            >批量导出</a-button>

            <a-button
              v-if="isHr"
              type="primary"
              shape="round"
              style="background: green; border-color: green"
              @click="dialogA=true"
            >异动导入</a-button>
            <a-button
              v-if="isHr"
              type="primary"
              shape="round"
              style="background: green; border-color: green"
              @click="dialogB=true"
            >合同导入</a-button>
            <a-button
              v-if="isHr"
              type="primary"
              shape="round"
              style="background: green; border-color: green"
              @click="dialogC=true"
            >职务导入</a-button>

          </a-space>
        </div>

        <Padding size="large" />

        <a-table
          v-if="showList"
          bordered
          :dataSource="list"
          :loading="loading"
          @change="onChange"
          :pagination="{
            total,
            current,
            pageSize,
            showTotal: (total) => `共 ${total} 条记录`,
          }"
          rowKey="id"
          :row-selection="{
            selectedRowKeys,
            onChange: onSelectChange,
          }"
        >
          <a-table-column title="姓名" align="center">
            <template slot-scope="text">
              <a
                href="#"
                v-if="$getPermission($route.path + '/detail')"
                @click.prevent="
                  $router.push($route.path + '/detail?id=' + text.id)
                "
              >{{ text.name }}</a>
              <span v-else>{{ text.name }}</span>
            </template>
          </a-table-column>
          <a-table-column title="性别" data-index="sex" align="center">
            <template slot-scope="sex">
              <DataDictFinder dictType="sex" :dictValue="sex" />
            </template>
          </a-table-column>
          <a-table-column title="部门" data-index="dept" align="center"></a-table-column>
          <a-table-column title="手机号" data-index="mobile" align="center"></a-table-column>
          <a-table-column title="职位" align="center">
            <template slot-scope="text">
              <a-select
                class="selectNoBorder"
                :disabled="true"
                show-search
                :filter-option="$selectFilterOption"
                size="small"
                placeholder="请选择"
                v-model="text.position"
              >
                <a-select-option
                  v-for="item in positionList"
                  :key="item.value"
                  :value="item.value"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </template>
          </a-table-column>
          <a-table-column title="最高学历" align="center">
            <template slot-scope="text">
              <DataDictFinder dictType="degree" :dictValue="text.degree" />
            </template>
          </a-table-column>

          <a-table-column title="党员" align="center">
            <template slot-scope="text">
              <DataDictFinder dictType="partyMemberType" :dictValue="text.partyMember" />
            </template>
          </a-table-column>

          <a-table-column title="状态" align="center">
            <template slot-scope="text">
              <DataDictFinder dictType="employeeStatus" :dictValue="String(text.status)" />
            </template>
          </a-table-column>

          <a-table-column key="control" title="操作" align="center">
            <template slot-scope="text">
              <a-space>
                <a
                  href="#"
                  v-if="$getPermission($route.path + '/edit')"
                  @click.prevent="
                    $router.push($route.path + '/edit?id=' + text.id)
                  "
                >编辑</a>

                <a
                  href="#"
                  v-if="$getPermission($route.path + '/detail')"
                  @click.prevent="
                    $router.push($route.path + '/detail?id=' + text.id)
                  "
                >详情</a>

                <a
                  href="#"
                  v-if="$getPermission($route.path + '/status/update')"
                  @click.prevent="changeStatus(text)"
                >状态变更</a>
              </a-space>
            </template>
          </a-table-column>
        </a-table>
        <div v-else>
          <Cards :organizationList="organizationList" :searchResult="searchResult" />
        </div>
      </div>
    </a-card>

    <a-modal title="异动导入" :visible="dialogA" :footer="null" @cancel="dialogA=false">
      <span>
        <input ref="uploadA" style="display: none" type="file" @change="onChangeA" />

        <a-button :loading="loading" @click="onClickA" style="margin-right: 10px;"
          >导入</a-button>
        <a :href="templateA" target="_blank">下载模板</a>
      </span>
    </a-modal>

    <a-modal title="合同导入" :visible="dialogB" :footer="null" @cancel="dialogB=false">
      <span>
        <input ref="uploadB" style="display: none" type="file" @change="onChangeB" />

        <a-button :loading="loading" @click="onClickB" style="margin-right: 10px;"
          >导入</a-button>
        <a :href="templateB" target="_blank">下载模板</a>
      </span>
    </a-modal>

    <a-modal title="职务导入" :visible="dialogC" :footer="null" @cancel="dialogC=false">
      <span>
        <input ref="uploadC" style="display: none" type="file" @change="onChangeC" />

        <a-button :loading="loading" @click="onClickC" style="margin-right: 10px;"
          >导入</a-button>
        <a :href="templateC" target="_blank">下载模板</a>
      </span>
    </a-modal>

    <a-modal title="状态变更" :visible="visible" :footer="null" @cancel="cancel">
      <a-radio-group v-model="selectedStatus">
        <a-radio
          v-for="item in employeeStatusList"
          :key="item.value"
          :value="item.value"
        >{{ item.name }}</a-radio>
      </a-radio-group>
      <Padding />
      <Padding />

      <a-date-picker v-if="String( selectedStatus) === '0' || String( selectedStatus) === '3' || String( selectedStatus) === '4'" v-model="leaveDate" />
      <Padding />
      <Padding />
      <div class="right">
        <a-space>
          <a-button @click="cancel">关闭</a-button>
          <a-button @click="sure" type="primary" :loading="saveLoading">确认修改</a-button>
        </a-space>
      </div>
    </a-modal>
  </div>
</template>

<script>
import organization from "@/mixins/organization";
import watermark from "@/mixins/watermark";
import Search from "./components/search.vue";
import FileUploader from "./components/file-upload.vue";
import Cards from "./components/cards.vue";
import {
  fetchList,
  downloadFile,
  editStatus,
  importChangeList,
  importContractList,
  importPositionHisList
} from "@/api/human-resources/employee";
import { saveAs } from "file-saver";
import { mapGetters } from "vuex";
import {
  replaceUrl,
} from "@/api/approval";
export default {
  name: "employee",

  mixins: [organization, watermark],

  components: {
    Search,
    Cards,
    FileUploader
  },

  data() {
    return {
      dialogA: false,
      dialogB: false,
      dialogC: false,
      templateA: 'https://qiniu.njszy.com/linkcloud/production/2024/5/31/4aa0efd035b3c2120e1fdc438641a97a_异动导入模板 .xlsx',
      templateB: 'https://qiniu.njszy.com/linkcloud/production/2024/5/31/565e2e3d5cab6b518ab2a5a61392cb33_合同导入模板.xlsx',
      templateC: 'https://qiniu.njszy.com/linkcloud/production/2024/5/31/7db7c5411bb4c2678056c730606da536_职务导入模版.xlsx',
      form: {},
      searchValues: {},
      loading: false,

      current: 1,
      pageSize: 10,
      list: [],
      total: 0,

      selectedRowKeys: [],

      showList: true,
      searchResult: [],

      text: {},
      visible: false,
      selectedStatus: null,
      leaveDate: null,
      saveLoading: false
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    ...mapGetters("auth", ["isHr"]),
    sex() {
      return this.findDataDict("sex");
    },
    employeeStatusList() {
      return this.findDataDict("employeeStatus");
    },
    lists() {
      return this.findDataDict("talentMap");
    },
    positionList() {
      return (
        this.lists.find((item) => item.value === "talentMap_post").children || []
      );
    },
  },

  activated() {
    this.getList();
    let urls = [this.templateA, this.templateB, this.templateC]
    replaceUrl({
      publicUrls : urls.join(','),
    }).then((data) => {
      const arr = data.split(',')
      this.templateA = arr[0]
      this.templateB = arr[1]
      this.templateC = arr[2]
    })     
  },

  methods: {
    onClickA(){
      this.$refs.uploadA.click();
    },
    onClickB(){
      this.$refs.uploadB.click();
    },
    onClickC(){
      this.$refs.uploadC.click();
    },
    onChangeA(e) {
      const files = e.target.files;
      const data = new FormData();
      for (let i = 0; i < files.length; i++) {
        data.append("file", files[i]);
      }

      this.loading = true;

      importChangeList(data)
        .then(() => {
        })
        .finally(() => {
          this.loading = false;
          e.target.value = null;
        });
    },
    onChangeB(e) {
      const files = e.target.files;
      const data = new FormData();
      for (let i = 0; i < files.length; i++) {
        data.append("file", files[i]);
      }

      this.loading = true;

      importContractList(data)
        .then(() => {
        })
        .finally(() => {
          this.loading = false;
          e.target.value = null;
        });
    },
    onChangeC(e) {
      const files = e.target.files;
      const data = new FormData();
      for (let i = 0; i < files.length; i++) {
        data.append("file", files[i]);
      }

      this.loading = true;

      importPositionHisList(data)
        .then(() => {
        })
        .finally(() => {
          this.loading = false;
          e.target.value = null;
        });
    },
    getList() {
      this.loading = true;
      fetchList({
        pageNum: this.current,
        pageSize: this.pageSize,
        ...this.form,
        ...this.searchValues
      })
        .then(res => {
          this.loading = false;
          if (res && Array.isArray(res.list)) {
            this.list = Object.freeze(res.list);
            this.total = res.totalSize;

            if (!this.showList && res.list.length) {
              this.searchResult = res.list;
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },

    onSelectChange(keys) {
      this.selectedRowKeys = keys;
    },

    query() {
      this.searchValues = {};
      this.current = 1;
      this.getList();
    },
    search(payload) {
      this.form = {};
      this.searchValues = payload;
      this.current = 1;
      this.getList();
    },
    reset() {
      this.form = {};
      this.searchValues = {};
      this.current = 1;
      this.getList();
    },

    onDownload() {
      downloadFile({
        ...this.form
      }).then(blob => {
        saveAs(blob, "导出结果.xlsx");
      });
    },

    changeStatus(text) {
      this.text = text;
      this.selectedStatus = text.status;
      this.visible = true;
    },
    cancel() {
      this.text = {};
      this.selectedStatus = null;
      this.visible = false;
    },
    sure() {
      console.log("this.text", this.text);

      if (String(this.selectedStatus) === "0" || String(this.selectedStatus) === "3" || String(this.selectedStatus) === "4") {
        if (!this.leaveDate) {
          this.$message.error("请选择日期");
          return;
        }
      }
      this.saveLoading = true;
      editStatus({
        id: this.text.id,
        status: this.selectedStatus,
        leaveDate:
        (String(this.selectedStatus) === "0" || String(this.selectedStatus) === "3" || String(this.selectedStatus) === "4")
            ? this.leaveDate.format("YYYY-MM-DD")
            : undefined
      })
        .then(() => {
          this.cancel();
          this.getList();
        })
        .finally(() => {
          this.saveLoading = false;
        });
    }
  }
};
</script>
<style>
.selectNoBorder .ant-select-selection{
  border: none;background: none;color: rgba(0, 0, 0, 0.65);
}
.selectNoBorder .ant-select-arrow{
  display: none;
}
.selectNoBorder .ant-select-selection__rendered{
  margin: 0;
}
</style>